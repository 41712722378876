/**
 * IE/legacy browser extras

 Enable if needed:
 */
@import "duke_functions";
@import "brand";
@import "variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/**
 * only target ie10+
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .alert > .icon {
    &:before {
      align-self: center;
      display: inline-block;
    }
  }
  wbr:after {
    content: "\200B";
  }
  select.with-placeholder-label
  {
    & ~ .placeholder-label {
      // try to match .on-top variant
      max-width: calc(100% - #{$input-padding-x} - (#{$input-border-width} * 2));

      top: calc(1em * (#{$line-height-base} / -2));
      // transform: scale(0.75);
      font-size: $input-font-size * 0.75;

      &:before {
        background-color: $card-bg;
      }
      color: duke_contrasted-color($input-placeholder-color, $card-bg);
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border: $input-border-width solid $input-border-color;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
        height: calc(50% - #{$input-border-width});
      }
    }

    &.form-control-lg ~ .placeholder-label.on-top,
    .input-group-lg & ~ .placeholder-label.on-top {

      max-width: calc(100% - #{$input-padding-x-lg} - (#{$input-border-width} * 2));

      font-size: $input-font-size-lg * 0.75;
      height: $input-line-height-lg * 1em;
      top: calc(1em * (#{$input-line-height-lg} / -2));
      &:before {
        border-radius: 0 0 calc(#{$input-border-radius-lg} - #{$input-border-width}) calc(#{$input-border-radius-lg} - #{$input-border-width});
      }
      &:after {
        border-radius: 0 0 $input-border-radius-lg $input-border-radius-lg;
      }
    }
    &.form-control-sm ~ .placeholder-label.on-top,
    .input-group-sm & ~ .placeholder-label.on-top  {

      max-width: calc(100% - #{$input-padding-x-sm} - (#{$input-border-width} * 2));

      font-size: $input-font-size-sm * 0.75;
      height: $input-line-height-sm * 1em;
      top: calc(1em * (#{$input-line-height-sm} / -2));
      &:before {
        border-radius: 0 0 calc(#{$input-border-radius-sm} - #{$input-border-width}) calc(#{$input-border-radius-sm} - #{$input-border-width});
      }
      &:after {
        border-radius: 0 0 $input-border-radius-sm $input-border-radius-sm;
      }
    }
  }


}
/*
@media all and (-ms-high-contrast: none) and (min-width: #{map-get($grid-breakpoints, sm)}), (-ms-high-contrast: active) and (min-width: #{map-get($grid-breakpoints, sm)}) {
  .alert-info:not(.floating),
  .alert-warning:not(.floating),
  .alert.static {
    .icon + div {
      flex-basis: 100%;
    }
  }
}
*/
