%standalone-content
{
  $comp-width: 600px !default;
  max-width: $comp-width;

  & .alert-danger,
  & .alert-success
  {

    @include media-breakpoint-down(xs) {
      max-width: $comp-width;
      min-width: 0;
    }
    @include media-breakpoint-up(sm) {
      max-width: calc(#{map-get($container-max-widths, sm)} - (#{$card-spacer-x} * 2) - (#{$card-border-width} * 2) - #{$grid-gutter-width});
      min-width: calc(#{map-get($container-max-widths, sm)} - (#{$card-spacer-x} * 2) - (#{$card-border-width} * 2) - #{$grid-gutter-width});
    }
    @include media-breakpoint-up(md) {
      max-width: calc(#{$comp-width} - (#{$card-spacer-x} * 2) - (#{$card-border-width} * 2));
      min-width: calc(#{$comp-width} - (#{$card-spacer-x} * 2) - (#{$card-border-width} * 2));
    }
  }
}
/* Sass can't handle placeholders and media queries at the same time, so bye bye placeholders.*/
%narrow-standalone-content
{
  @include media-breakpoint-up(sm) {
    $comp-width: 410px !default;
    max-width: $comp-width;

    & .alert-danger,
    & .alert-success,
    & .alert.floating {
      max-width: calc(#{$comp-width} - (#{$card-spacer-x} * 2) - (#{$card-border-width} * 2));
      min-width: calc(#{$comp-width} - (#{$card-spacer-x} * 2) - (#{$card-border-width} * 2));
    }
  }
}
