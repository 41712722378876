

html
{
  height: 100%;
  overflow: auto;
}
$duke-body-background: none !default;
body
{
  min-height:100%;
  overflow: auto;
  background: $duke-body-background;
}
svg
{
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
a:focus {
  outline-style: none;
}
app-root
{
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
article.container
{
  flex: 1 1 auto;
  body.embedded &
  {
    height: auto;
  }
  body.no-header &
  {
    padding-top: map-get($spacers, 5);
  }
  body.no-footer & {
    padding-bottom: map-get($spacers, 4);
  }
}

article.container > router-outlet ~ *:not(footer):not(header)
{
  z-index: $zindex-modal + 1;
}
@include media-breakpoint-down(xs) {
  article.container
  {
    // max-width: calc(100vw - #{$grid-gutter-width}); //
    padding-left: 0;
    padding-right: 0;
    body.no-header &
    {
      padding-top: map-get($spacers, 4);
    }
  }
  article.container > header {
    flex: 0 0 auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
  article.container > router-outlet ~ *:not(footer):not(header),
  #catastrophicFailure {
    flex: 1 1 auto;
  }
  article.container > footer {
    flex: 0 0 auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }


}
.alert-danger:not(.static), .alert-success:not(.static), .alert.floating
{
  z-index: $zindex-modal + 2;
}
article.container > router-outlet ~ *:not(footer)
{
  flex-shrink: 0;
}
a.nav-link,
.nav-tabs a.nav-link,
.nav-tabs .nav-item.show a.nav-link
{
  color: $text-muted;
  &:hover,
  &:active,
  &:focus,
  &.active
  {
    color: $body-color;
  }

}

.session-panel {
  position: absolute;
  top: 0;
  right: map_get($spacers, 3);
  z-index: $zindex-modal + 1;
}
  .session-popover
  {
    min-width: 240px;
    max-width: calc(100vw - 2rem);
  }
  .session-popover.bs-popover-bottom.bs-popover-bottom-right
  {
    top: 4.7rem;
    left: auto;
    right: 1rem;
    transform-origin: 100% 0%;
    transform: scale(0);
    transition: transform 200ms ease-out;
    visibility: hidden;
    &.show
    {
      visibility: visible;
      transform: scale(1);
    }
    & .arrow
    {
      left: auto;
      right: 2em;
      margin-left: -0.5rem;
    }
  }
.localeNotification
{
  @extend %narrow-standalone-content;
}

.logo {
  @include transition($transition-base);
  opacity: 1;
  transform: scale(1, 1);
  &.loading {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
}

$duke-preferred-logo-height: 100px !default;
article.container > header
{
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  width: 100%;

  .logo-holder {
    text-align: center;
    .logo {
      height: $duke-preferred-logo-height;
      display: inline-block;
      img,
      svg
      {
        max-height: 100%;
        max-width: 100%;
      }
    }
    a {
      &:focus {
        outline: none;
      }
      &::-moz-focus-inner {
        border: 0;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    & {
      // align logo to bottom of header
      display: flex;
      align-items: flex-end;
    }
    .logo-holder .logo
    {
      height: auto;
      max-height: $duke-preferred-logo-height;
      img,
      svg
      {
        max-height: $duke-preferred-logo-height;
      }
    }
  }

}
article.container > footer {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  width: 100%;
  transition: max-width 200ms ease-out;
  .navbar
  {
    @if (duke_yiq($body-bg) > $yiq-contrasted-threshold/*$duke-color-scheme == "light"*/) {
      @extend .navbar-light;
    } @else {
      @extend .navbar-dark;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .navbar-brand,
  & .navbar-light .navbar-brand {
    text-align: center;
    @include font-size($small-font-size);
    &,
    &:focus,
    &:hover,
    &:active {
      color: $text-muted;
    }
  }
  .navbar .dropdown-toggle:after {
    vertical-align: baseline;
  }
  a {
    color: inherit;

    &:focus
    {

      outline-style: dotted;
      outline-color: rgba($link-color, 0.5)   ;
      outline-offset: 0.125rem;
      outline-width: 0.125rem;
    }
  }
}
$duke-tooltip-box-shadow:           none!default;
.tooltip.show .tooltip-inner
{
  @include box-shadow($duke-tooltip-box-shadow);
  color: color-yiq($tooltip-bg);
}
.tab-content
{
  position: relative;
  overflow: hidden;
  /*
    Create space for the input focus glow effect
  */
  .card-body & {
    padding: 5px;
    margin: -5px;
  }
}
.tab-content > .tab-pane
{
  display:block;
  // setting 100% explicitly breaks rendering on FF
  width: auto;
  top: -100%;
  overflow: hidden;
  position: absolute;
  max-height: 100%;
  visibility: hidden;
  &.active
  {
    top: 0;
    position: static;
    overflow: visible;
    max-height: unset;
    visibility: visible;
  }
}

.visual-link
{
  color: $link-color;
  text-decoration: $link-decoration;
  display:inline-block;
  a:hover &
  {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

}


#catastrophicFailure + footer,
#catastrophicFailure .standalone {
  @extend %narrow-standalone-content;
}

.nested-flex-fix-for-ie {
  flex: 1 1 0%;
}
.btn-facebook
{
  @if $duke-outline-buttons {
    @include button-outline-variant(#3b5998);
  } @else {
    @include button-variant(#3b5998, #3b5998);
  }
}
.btn-google
{
  @if $duke-outline-buttons {
    @include button-outline-variant(#DB4437);
  } @else {
    @include button-variant(#DB4437,#DB4437);
  }
}
.btn-adfs
{
  @if $duke-outline-buttons {
    @include button-outline-variant(#00a8e8);
  } @else {
    @include button-variant(#00a8e8,#00a8e8);
  }
}




.card,
.card.standalone {
  border-color: transparent;
}

.form-control {
  border-bottom-width: 3px;
  &,
  &:focus {
    border-color: $gs-blue;
  }
}
.was-validated .form-control:invalid,
.form-control.is-invalid
{
  &,
  & ~ .placeholder-label
  {
    color: $gs-orange-darkened;
  }
  &.has-value ~ .placeholder-label
  {
    color: $gs-orange-faded;
  }
  &,
  &:focus {
    border-color: $gs-blue;
  }
}
.was-validated .form-control:valid,
.form-control.is-valid
{
  &,
  &:focus {
    border-color: $gs-blue;
  }
}
.form-control, {
  &.has-value ~ .placeholder-label,
  &:focus.has-value ~ .placeholder-label,
  &:focus ~ .placeholder-label,
  & ~ .placeholder-label .on-top {
    color: $gray-600;
  }
}

.info-feedback,
.placeholder-label {
  color: #666666;
}
.feedback
{
  margin-top: 0.5rem;
  margin-bottom: 0;
  letter-spacing: 0.15px;
  &,
  &.text-right {
    text-align: left !important;
  }

}
.invalid-feedback {
  color: $gs-orange-darkened;
  font-weight: bold;
  // effectively removes the non-visible feedback from the layout, needed to keep input alignment when the default
  // .feedback negative margin trick is not used.
  max-height: 0;
}
.form-control.has-value ~ .placeholder-label,
.form-control:focus ~ .placeholder-label,
.placeholder-label.on-top {
  text-transform: uppercase;
  letter-spacing: 0.15px;
}

.custom-control-input ~ .custom-control-label
{
  &::before {
    border-color: $gs-blue;
  }
}
.custom-control-input:checked ~ .custom-control-label
{
  &::before {
    border-color: $gs-blue;
    background-color: $gs-blue;
  }
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: rgba($gs-blue, 0.5);
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label,
.custom-control-input.is-valid:checked ~ .custom-control-label
{
  color: #000000;
  &::before {
    border-color: $gs-blue;
    background-color: $gs-blue;
  }
}

.btn-auto {

  @include button-variant($gray-100, transparent, $gray-400, transparent, $gray-400, transparent);
}
.btn-primary,
.btn-success,
.btn-danger,
.validate-email-component:not(.error) .card-footer .btn-info
{
  @include button-variant($gs-orange, transparent, $gs-orange-darkened, transparent,$gs-orange-darkened, transparent);
  &,
  &:disabled,
  &.disabled,
  &:hover,
  &:active,
  &:focus {
    color: #FFFFFF;
  }
  &:disabled,
  &.disabled
  {
    background-color: $gs-orange-faded;
  }
}
.btn-secondary,
.session-popover .btn-danger,
#login.tab-pane .btn-primary,
.input-group .btn-danger,
.login-component #passwordTab .alert-info .btn-info,
.authenticate-device-component .card-footer .btn-success + .btn-danger,
.validate-email-component .btn-link
{
  @include button-variant(#FFFFFF, $gs-blue, $gs-blue, transparent, $gs-blue, transparent);

  color: $gs-blue;
  &:hover,
  &:active,
  &:focus {
    color: #FFFFFF;
  }
  &:disabled,
  &.disabled
  {
    background-color: $gs-blue-faded;
    border-color: $gs-blue-faded;
    color: #FFFFFF;
  }
}
.btn-info {
  color: #FFFFFF;
  &:not(:disabled) {
    @include hover {
      color: #FFFFFF;
    }
  }
}

.card.standalone > .card-footer,
.card.standalone *:not(.card) .card-footer {
  .btn-primary,
  .btn-success
  {
    margin-right: map-get($spacers, 3);
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .btn-secondary
  {
    text-decoration: $link-decoration;
    &,
    &:hover,
    &:active,
    &:focus
    {
      border-color: transparent;
      background-color: transparent;
      color: $link-color;
    }
    @include hover() {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }

    &:focus,
    &.focus {
      border-color: transparent;
      background-color: transparent;
      text-decoration: $link-hover-decoration;
    }

    &:disabled,
    &.disabled {
      color: $btn-link-disabled-color;
      pointer-events: none;
    }

    // No need for an active state here
  }
  .flex-row-reverse {
    flex-direction: row !important;
  }
}

.agreements-component,
.register-component,
.login-component,
.forgot-password-component,
.validate-email-component,
.logout-component,
.join-component,
.authenticate-device-component,
.activate-component
{
  &.card.standalone > .card-footer,
  &.card.standalone *:not(.card) .card-footer {
    .btn-primary,
    .btn-success,
    .btn-info {
      margin-bottom: map-get($spacers, 3);
      margin-right: 0;
    }
    & div {
      width: 100%;
    }

    .btn {
      display: block;
      width: 100%;
    }
    .flex-row-reverse {
      flex-direction: column !important;
    }
  }
}
.validate-email-component .alert-danger.static
{
  border: none;
  background: transparent;
  text-align: center;
  .icon
  {
    display:none !important;
  }
  strong
  {
    display: block;
  }

}

.input-group > .input-group-append > .btn
{
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: 1.5rem;
}
.input-group-sm > .input-group-append > .btn
{
  padding-left: $btn-padding-x-sm * 2;
  padding-right: $btn-padding-x-sm * 2;
  margin-left: map-get($spacers, 2);
}

.input-group > .input-group-append,
.input-group > .input-group-prepend
{
 .btn {
   border-radius: $btn-border-radius!important;
 }
}
.tooltip-inner
{
  background-color: $gs-orange;
  color: #FFFFFF;
}

.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: $gs-orange;
    }
  }
}
.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: $gs-orange;
    }
  }
}
.bs-tooltip-left {
  .arrow {
    &::before {
      border-left-color: $gs-orange;
    }
  }
}
.bs-tooltip-right {
  .arrow {
    &::before {
      border-right-color: $gs-orange;
    }
  }
}
.btn-link
{
  font-weight: $btn-font-weight;
}
.card-deck {
  .card-header.text-center {
    text-align: left!important;
  }
  @include media-breakpoint-up(sm) {
    display: block;
    flex-flow: unset;
    margin-right: 0;
    margin-left: 0;

    .card {
      flex: unset;
      margin-left: 0!important;
      margin-bottom: $card-deck-margin;
      margin-right: 0!important;
    }
  }
  .card {
    .ml-sm-1,
    .mx-sm-1,
    .mr-sm-1 {
      margin-left: 0!important;
      margin-right: 0!important;

    }
  }
}

article.container > header .logo-holder,
article.container > footer .navbar
{
    padding-left: 0;
    padding-right: 0;
}

.alert-info:not(.floating)
{
  color: inherit;
  background-color: transparent;
  border-color: transparent;
  text-align: center;
  padding: 0;
  .icon
  {
    display: none!important;
  }
  .btn {
    display: block;
    width: 100%;
  }
  @include media-breakpoint-up(sm) {
    flex-direction: column !important;
    & > .icon {
      margin-left: -$alert-padding-x;
      margin-right: -$alert-padding-x;
      margin-top: -$alert-padding-y;
      margin-bottom: $alert-padding-y;
      padding-top: $alert-padding-y;
      padding-bottom: $alert-padding-y;
      width: calc(100% + (#{$alert-padding-x} * 2));
    }
  }
}
article.container > header.has-session
{
  max-width: unset!important;
  height: 4.7rem;
  margin-top: 2rem;

  position: relative;
  left: -50vw;
  margin-left: 50%;

  .logo-holder
  {
    position: absolute;
    left:1rem;
    top:-2rem;
    z-index: $zindex-modal;
    // full width minus the session panel (#{$card-spacer-x} * 2) -
    max-width: calc(100vw - 120px - 2rem);
    article.ng-animating & {
      @each $breakpoint, $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          left: calc(-0.5 * (100vw - #{$container-max-width})) !important;
        }
      }
    }
  }
  .logo {
      background-color: rgba(255,255,255, 0.5)
  }
}
article.ng-animating {
  overflow-x: visible !important;
  overflow-y: clip !important;
}


.card-body .tab-content
{
  padding-top: map-get($spacers, 4);
}
@if($duke-hide-icons) {
  // .icon class missing in core product
  .manage-emails-component .btn .fa-user-check
  {
    display: none;
  }
  // This is intentionally not hidden in core, but as customer removed the tooltips its not very useful so hiding with
  // the rest of the icons
  .edit-profile-component .tab-notification {
    display: none;
  }
}
.configure-totp-component .card-body app-duke-alert:first-child .alert-info:not(.floating) {
  .custom-totp-link a {
    font-weight: bold;
  }
}
%hovering-alert,
.popover
{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    border-color: transparent;
    & > .icon {
      display: flex !important;
    }
    @if ($color == success) {
      background-color: $gs-alert-success;
      color: $gs-dark;
    } @else if ($color == danger) {
      background-color: $gs-alert-danger;
      color: $gs-dark;
    } @else if ($color == warning) {
      background-color: $gs-alert-warning;
      color: $gs-dark;
    }
    #custom-registration-message-edu > &,
     &#custom-registration-message-edu {
         background-color: $gs-orange;
         color: #FFFFFF;
     }
  }
}

.session-panel .btn-sessiopanel-trigger {
  border-radius: 0;
  padding-left: $input-btn-padding-x;
  padding-right: $input-btn-padding-x;

  &,
  &:hover:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:disabled,
  &.disabled {
    border-color: transparent;
    background-color: $duke-body-background;
    color: #000000;
  }
  .profile-picture
  {
    margin-right: 0.8rem;
  }
  .fa
  {
    font-size: 1.5em;
  }
}
.session-popover .profile-picture.mr-2
{
    margin-right: 0.8rem !important;
}
#usernameHolder {
  font-weight: normal;
  letter-spacing: initial;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, 1.5, $btn-border-radius);
  text-align: left;
  margin-top: map-get($spacers, 3);
  &:after
  {
    display:none;
  }
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: "\f053";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: map-get($spacers, 1);
    color: #878787;
  }
}
.manage-emails-component .input-group {
  @extend .input-group-sm;
}
.manage-emails-component {
  .card-body .alert-warning
  {
    &,
    & .form-control {
      background: #f8f8f8;
    }
    .icon
    {
      display: none!important;
    }
    .placeholder-label:before
    {
      background-color: #f8f8f8;
    }
    .placeholder-label:before,
    .placeholder-label:after
    {
      color: #f8f8f8;
    }
    .placeholder-label{
      color: #878787;
    }
  }
}
.nav-tabs {
  border-bottom: none;
}
.edit-profile-component .nav-tabs .nav-item,
.nav-tabs .nav-item
{
  .nav-link {
    border-width: 0 0 2px 0;
    margin-bottom: 3px;
    border-color: $gs-blue;
    color: #000000;
    padding:0;
    text-align: left;
    justify-content: flex-start;
  }
  &:not(:first-child) .nav-link {
    margin-left: 0.88888rem;
  }
  &:not(:last-child) .nav-link {
    margin-right: 0.88888rem;
  }
}
.edit-profile-component .nav-tabs .nav-link.active,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link
{
  border-width: 0 0 5px 0;
  margin-bottom: 0px;
  border-color: $gs-blue;
  font-weight: bold;
}

.gs-footer
{
  padding: 0.5rem 1rem;
  font-size: 14px;
  background-color: $gray-300;
  &,
  & a {
    color: $gs-dark;
  }
  .copyright,
  .group {
    flex-basis: 280px;
  }
  .links
  {
    flex-basis: 420px;
  }
}

body {
  app-register ~ footer,
  app-register ~ header,
  .register-component {
    @extend %narrow-standalone-content;
  }
  app-validate-email {
    & ~ footer,
    & ~ header,
    & .validate-email-component {
      @extend %narrow-standalone-content;
    }
  }
  app-agreements {
    & ~ footer,
    & ~ header,
    & .agreements-component {
      @extend %narrow-standalone-content;
    }
  }
  app-change-password {
    & ~ footer,
    & ~ header,
    & .change-password-component {
      @extend %standalone-content;
    }
  }
  app-manage-emails {
    & ~ footer,
    & ~ header,
    & .manage-emails-component {
      @extend %standalone-content;
    }
  }
  app-manage-agreements {
    & ~ footer,
    & ~ header,
    & .manage-agreements-component {
      @extend %standalone-content;
    }
  }
}

.configure-totp-component .alert-info:not(.floating),
.manage-emails-component .alert-info:not(.floating),
.manage-agreements-component .alert-info:not(.floating),
.agreements-component .alert-info:not(.floating)
{
  text-align: left;
}
.card.standalone > .card-body,
.card.standalone *:not(.card) .card-body
{
  padding-bottom: 0;
  padding-top: 0;
}

.register-component .card-header p {
  display:none;
}
.forgot-password-component {
  .alert.alert-info .h6 {
    display: none;
  }
}
.form-group {
  // create space for the offset label, margin collapse causes this to not affect adjacent input layout
  margin-top: 0.5rem;
}

.edit-profile-component,
.view-profile-component,
.configure-totp-component,
.manage-emails-component,
.manage-agreements-component:not(.error),
.change-password-component {
  &.card.standalone > .card-header,
  &.card.standalone *:not(.card) .card-header {
    text-align: left!important;
    margin-bottom: 1.5rem;
    h1
    {
      font-size: $font-size-base * 1.77778;

    }
  }

  &.card.standalone > h1.card-header,
  &.card.standalone *:not(.card) h1.card-header {
    font-size: $font-size-base * 1.77778;
  }
}
.agreements-component
{
  &.card.standalone > .card-header,
  &.card.standalone *:not(.card) .card-header {
    text-align: left !important;
    margin-bottom: 1.5rem;
  }
}
.popover-body
{
  padding-top: 0.5rem;
}

.view-profile-component .profile-picture {
  margin-right: map-get($spacers, 2);
  & + br {
    display: none;
  }
}
.profile-picture
{
  font-size: 0.8em;
  width: 2.1em;
  height: 2.1em;
  line-height: 2.1em;
}
.session-panel .btn-sessiopanel-trigger .profile-picture,
{
  font-size: 1.2em;
  width: 1.66666em;
  height: 1.66666em;
  line-height: 1.66666em;
}

.session-popover .profile-picture
{
  font-size: 1.33333em;
  width: 1.66666em;
  height: 1.66666em;
  line-height: 1.66666em;
}
.view-profile-component.card.standalone .card-deck a.card
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .card-header
  {
    order: 0;
    flex-grow: 1;
    margin-bottom: 0;
    padding: $card-spacer-y*0.5 $card-spacer-x*0.5 !important;
    h2 {
      font-size: 24px;
    }
  }
  .card-footer
  {
    order: 1;
    flex-grow: 0;
    line-height: 32px;
    letter-spacing: 0.15px;
    font-size: 20px;
    font-weight: 700;
    padding: $card-spacer-y*0.5 $card-spacer-x*0.5 !important;
  }
  .card-body
  {
    width: 100%;
    order: 2;
    padding-left: $card-spacer-x*0.5;
    padding-right: $card-spacer-x*0.5;
    &.small
    {
      font-size: initial!important;
    }
    strong
    {
      font-weight: normal;
    }
  }
  &:hover,
  &:focus,
  &:active
  {
    border-color: #f8f8f8;
    background-color: #f8f8f8;
  }
  &:last-child
  {
    margin-bottom: 0;
  }
  .list-unstyled {
    margin-bottom: 0;
  }
}

/**
 * Error screen styles
 */
body .page-not-found-component.card.standalone,
body .invalid-api-component.card.standalone,
body .join-component.card.standalone.error,
body .login-component.card.standalone.error,
body .agreements-component.card.standalone.error,
body .manage-agreements-component.card.standalone.error,
body .validate-email-component.card.standalone.error
{
  .card-header h1,
  h1.card-header {
    &:before
    {
      display: block;
      content: "";
      width: 80px;
      height: 80px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem!important;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4MCIgaGVpZ2h0PSI4MCIgZmlsbD0ibm9uZSI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBzdHJva2U9IiNGRjYxMDQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSI0IiBkPSJNNS43MzMgNzguNDU5Yy0zLjExNyAwLTQuMTU2LTIuMDc4LTMuMTE3LTQuNjc1TDM3LjQyIDUuMjE3YzEuMDM5LTIuNTk3IDMuMTE3LTIuNTk3IDQuNjc1IDBsMzQuMjgzIDY4LjU2N2MxLjA0IDIuNTk3IDAgNC42NzUtMy4xMTYgNC42NzVINS43MzNaIi8+PHBhdGggc3Ryb2tlPSIjRkY2MTA0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSI4IiBkPSJNMzkuNDk2IDI5LjExMVY0OS44OSIvPjxjaXJjbGUgY3g9IjM5LjQ5NSIgY3k9IjY1LjQ3MiIgcj0iNS4xOTQiIGZpbGw9IiNGRjYxMDQiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDgwdjgwSDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+");
    }
  }
  background-color: $card-bg;
  color: #000000;
  border-color: transparent;
  .card-footer {
    margin-top: 2rem;
    text-align: center!important;
  }
  .card-footer .btn-secondary {
    font-size: 20px;
    @include button-variant(#FFFFFF, $gs-blue, $gs-blue, transparent, $gs-blue, transparent);
    width: initial;
    display: inline-block;
    color: $gs-blue;
    padding-left: 3em;
    padding-right: 3em;
    &:hover,
    &:active,
    &:focus {
      color: #FFFFFF;
    }
    &:disabled,
    &.disabled
    {
      background-color: $gs-blue-faded;
      border-color: $gs-blue-faded;
      color: #FFFFFF;
    }
  }
}
body .validate-email-component.card.standalone.error
{
  .card-footer .btn ~ .btn-secondary {
    width: 100%;
    display: block;
  }
}

.btn,
.form-control
{
  letter-spacing: 0.15px;
}

.feedback
{
  overflow-y: initial;
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-feedback,
.checkbox-group.is-invalid > .invalid-feedback,
.feedback.info-feedback
{
  // the chosen proxima-nova font is somehow large than it's defined size which messes up the calculations for
  // height/scrollbars
  max-height: unset;
  overflow-y: initial;
}

.placeholder-label
{
  top: -0.125em;
}
.form-control.has-value ~ .placeholder-label,
.form-control:focus ~ .placeholder-label,
.placeholder-label.on-top
{
  font-size: $input-font-size * 0.66667;
  top: calc((1em / 0.66666) * (#{$line-height-base} / -2));
}
.form-group {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
body .edit-profile-component .nav-tabs .tab-label
{
  font-size: $font-size-base * 1.33333;
}
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  font-size: $font-size-base * 1.11111;
  line-height: 1.1;
}
.edit-profile-component,
.view-profile-component
{
  .card-footer .agreements {

    border-top: 2px solid $gray-400;
    margin-top: 1.5rem;
    padding-top: 1rem;

    li {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      line-height: 1em;

      span:not(.icon),
      &:not(:last-child) a {
        display: block;
        color: #000000;
        &.text-muted {
          color: $gray-600 !important;
          letter-spacing: 0.15px;

        }
      }
      &:last-child {
        margin-top: 1.5rem;
      }
    }
  }
}
.manage-agreements-component
{
  .checkbox-group {
    & > div
    {
      margin-bottom: 1.5rem;
      line-height: 1em;
      .custom-control-label {
        a,
        em,
        span {
          display: block;
          color: #000000;
          font-style: normal;
          strong {
            font-weight: normal !important;
          }
          &.agreement-accepted,
          &.agreement-rejected,
          &.agreement-required-soon,
          &.agreement-new
          {
            color: $gray-600 !important;
            letter-spacing: 0.15px;

          }
        }
      }
    }
  }
}

.manage-agreements-component:not(.error) .card-body
{
  padding-bottom: 2rem;
}
.register-component,
.agreements-component
{
  .checkbox-group > div {
    margin-bottom: 0.75rem;
    font-weight: bold;
  }
  .badge-muted {
    color: inherit;
    background: transparent;
    padding: 0;
    font-weight: normal;
    font-size: inherit;
    text-transform: lowercase;
    font-style: inherit;
    line-height: inherit;
    text-align: inherit;
    transition: none;
    vertical-align: inherit;
    border-radius: unset;
    &:before {
      content:"("
    }
    &:after {
      content:")"
    }
  }
}
select.form-control {
  padding-bottom: 0;
}
.form-control
{
  &:disabled,
  &[readonly] {
    color: #000000;
  }
}
.modal,
.modal-backdrop
{
  z-index: 1060!important;
}
.authenticate-device-component hr
{
  display: none;
}
.validate-email-component .card-footer .d-block
{
  width: 100%;
}
.login-component .card-header {
  font-size: 1rem;
}

.alert p
{
  & span,
  & strong,
  & em {
    // attempting to target all potential long userinput elements from alerts (mainly email) to force a line-break
    word-break: break-word;
  }
}

body {
  display: flex;
  flex-direction: column;
}
app-root
{
  height: unset;
  flex-grow: 1;
}
