// 10Duke brand

$duke-color-scheme:                 "light";

$gs-blue-darkened: #2C3454;
$gs-blue: #313D6B;
$gs-blue-faded: #979DB4;

$gs-orange-darkened: #D35414;
$gs-orange: #FF6104;
$gs-orange-faded: #FFAF81;

$gs-grayblue-darkened: #7D8699;
$gs-grayblue: #A4B2CD;
$gs-grayblue-faded: #D1D8E5;

$gs-dark: #011622;


$gray-100: #F8F8F8;
// gs colors have less shades so intentionally same
$gray-200: #E3E4E5;
$gray-300: #E3E4E5;
// gs colors have less shades so intentionally same
$gray-400: #C4C4C6;
$gray-500: #C4C4C6;
// gs colors have less shades so intentionally same
$gray-600: #878787;
$gray-700: #878787;
// gs colors have less shades so intentionally same
$gray-800: #6B6A6D;
$gray-900: #6B6A6D;


$gs-green: #11a105;

$gs-alert-success: #D8FFD5;
$gs-alert-danger: #FFDCC8;
$gs-alert-warning: #FEE9B9;

$dark: $gs-dark;

$primary:                           $gs-orange;
$secondary:                         $gs-blue;
$success:                           $gs-green;
$danger:                            $gs-orange;
$info:                              #0d8ea9;
$warning:                           #e6a205;

$link-color: $gs-blue;

@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:700&display=swap');
$font-family-monospace:             "Roboto Mono", monospace;

@import url('https://assets.graphisoft.com/typography/proximanova.css');
$font-family-base: "proxima-nova", sans-serif;

$duke-icon-highlight-color:         $gs-orange;
$duke-profile-pic-color: #FFFFFF;
$duke-profile-pic-bg-color: $gs-blue;
$duke-profile-pic-bg-image:         none;

$duke-color-scheme-base: #FFFFFF;
$duke-body-background: #FFFFFF;

$form-feedback-font-size: 100%;

$input-border-color: $gs-blue;
$input-border-width: 0px;
$input-border-radius:                   0;
$input-border-radius-lg:                $input-border-radius;
$input-border-radius-sm:                $input-border-radius;
$input-padding-x: 0;
$input-padding-x-lg: 0;
$input-padding-x-sm: 0;
$card-border-radius: 0;
$custom-control-indicator-border-width: 2px;

$input-btn-border-width: 2px;
$btn-border-radius:           10rem;
$btn-border-radius-lg:        10rem;
$btn-border-radius-sm:        10rem;

$input-focus-width: 0;
$input-focus-box-shadow: none;
/*
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;
*/
$enable-shadows: false;
$duke-hide-icons: true;
$btn-font-weight: bold;
$btn-disabled-opacity: 1;

$hr-border-color: transparent;
$duke-preferred-logo-height: 68px;

$tooltip-bg: $gs-orange;

$duke-alert-style: "faded";
$popover-header-bg: transparent;
$popover-header-padding-y:          1rem;
$popover-header-padding-x:          1.25rem;

$line-height-base:            1.25;

$line-height-lg:              $line-height-base;
$line-height-sm:              $line-height-base;

$headings-line-height:        1.33333;
$font-size-base:                    1.125rem;
$font-size-lg: $font-size-base;
$h1-font-size:                      $font-size-base * 1.33333;
$h2-font-size:                      $font-size-base * 1.33333;
$h3-font-size:                      $font-size-base * 1.33333;
$h4-font-size:                      $font-size-base * 1.2;
$h5-font-size:                      $font-size-base;
$h6-font-size:                      $font-size-base;
$headings-font-weight: 700;

$input-btn-font-size: $font-size-base * 1.11111;
$input-font-size: $font-size-base;
$input-color: #000;
$popover-border-radius: 10px;

$alert-padding-y:                   1.875rem;
$alert-padding-x:                   1.875rem;
