$form-text-margin-top:              0.125rem;
$form-group-margin-bottom:          1.5rem;
$custom-file-text:                  (
                                      en: "Browse",
                                      fi: "Selaa"
                                    );

$duke-preferred-logo-height: 100px !default;

$duke-default-colors:               (
                                      "light": (
                                        "primary":    #0e74a5,
                                        "secondary":  #6e7577,
                                        "success":    #77B300,
                                        "info":       #886699,
                                        "warning":    #FF8800,
                                        "danger":     #CC0000,
                                        "light":      #fafafa,
                                        "dark":       #333333
                                      ),
                                      "dark": (
                                        "primary":    #2A9FD6,
                                        "secondary":  #4a5055,
                                        "success":    #77B300,
                                        "info":       #9933CC,
                                        "warning":    #FF8800,
                                        "danger":     #CC0000,
                                        "light":      #fafafa,
                                        "dark":       #333333
                                      ));

$duke-color-scheme:                 "light" !default;


$duke-hide-icons:                   false !default;
$duke-outline-buttons:              false !default;
  $enable-validation-icons:           $duke-hide-icons == false;

$enable-shadows:                    true !default;
$enable-caret:                      true !default;
$enable-rounded:                    true !default;
$enable-gradients:                  false !default;
$enable-transitions:                true !default;



$duke-adjust-colors-for-contrast:        true;
$enable-grid-classes:               true !important;
$enable-print-styles:               false;
$enable-pointer-cursor-for-buttons: true;



$primary:                           map-get(map-get($duke-default-colors, $duke-color-scheme), "primary") !default;
$secondary:                         map-get(map-get($duke-default-colors, $duke-color-scheme), "secondary") !default;
$success:                           map-get(map-get($duke-default-colors, $duke-color-scheme), "success") !default;
$info:                              map-get(map-get($duke-default-colors, $duke-color-scheme), "info") !default;
$warning:                           map-get(map-get($duke-default-colors, $duke-color-scheme), "warning") !default;
$danger:                            map-get(map-get($duke-default-colors, $duke-color-scheme), "danger") !default;
$light:                             map-get(map-get($duke-default-colors, $duke-color-scheme), "light") !default;
$dark:                              map-get(map-get($duke-default-colors, $duke-color-scheme), "dark") !default;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
      "primary":    $primary,
      "secondary":  $secondary,
      "success":    $success,
      "info":       $info,
      "warning":    $warning,
      "danger":     $danger,
      "light":      $light,
      "dark":       $dark
    ),
    $theme-colors
);

$yiq-contrasted-threshold:  128 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
 $yiq-text-dark:             $dark !default;
 $yiq-text-light:            $light !default;

/*
// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  128 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;
*/
$duke-color-scheme-base:                   map-get($theme-colors, $duke-color-scheme) !default;

$body-bg-level: ('light': 1%, 'dark': 3%);
$body-bg:                           darken($duke-color-scheme-base, map-get($body-bg-level, $duke-color-scheme));
$duke-body-background:              $body-bg !default;

$body-color:                        duke_contrasted-color(map-get($theme-colors, if($duke-color-scheme == "light", "dark", "light")), $duke-color-scheme-base);
// $body-color:                        map-get($theme-colors, if($duke-color-scheme == "light", "dark", "light"));

$fa-font-path:                      "~@fortawesome/fontawesome-free/webfonts";

$font-size-base:                    1rem !default;
$h1-font-size:                      $font-size-base * 1.9 !default;
$h2-font-size:                      $font-size-base * 1.7 !default;
$h3-font-size:                      $font-size-base * 1.5 !default;
$h4-font-size:                      $font-size-base * 1.3 !default;
$h5-font-size:                      $font-size-base * 1.1 !default;
$h6-font-size:                      $font-size-base !default;

// Copied in from bs for branding tools
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base:                  $font-family-sans-serif !default;
// assign inherited default as value directly for branding tools
$headings-font-family:              $font-family-base !default;

$card-cap-bg:                       transparent;
$card-bg:                           $duke-color-scheme-base; //mix(#FFFFFF, $body-bg, 12.5%);
$card-border-color:                 rgba($body-color, .25);
$duke-standalone-card-border-color: rgba($body-color, .125);

$component-active-color:            #FFF !default;
$component-active-bg:               if($primary == $card-bg, darken($primary, 5%), $primary) !default;

$text-muted:                        duke_contrasted-color(mix($body-color, $body-bg, 75%), $card-bg);

$modal-content-bg:                  $card-bg;
$modal-content-border-color:        $card-border-color;
$modal-header-border-color:         transparent;
$modal-footer-border-color:         $modal-header-border-color;

$popover-bg:                        $card-bg;
$popover-border-color:              $card-border-color;


$tooltip-bg:                        $info;
// does not work here for some reason, so we override in class
// $tooltip-color:                     color-yiq($tooltip-bg);
$duke-tooltip-box-shadow:           0 .25rem .5rem rgba(#000000, .2);

$nav-pills-link-active-color:       $body-color;
$nav-pills-link-active-bg:          rgba($body-color, .1);
$duke-nav-pills-link-hover-bg:      rgba($body-color, .05);

$nav-tabs-link-active-bg:           $card-bg;
$nav-tabs-border-color:             rgba($body-color, .25);
$nav-tabs-link-hover-border-color:  rgba($body-color, .125) rgba($body-color, .125) transparent;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;

$duke-alert-style:                  'solid-icon' !default;
$duke-alert-color-levels:           (
  'solid-icon': 0,
  'faded': 0,
  'solid': -12.5
);
$duke-alert-bg-levels:           (
  'solid-icon': -11,
  'faded': -12,
  'solid': 0
);
@if $duke-color-scheme == "dark" {
  $duke-alert-bg-levels: map-merge($duke-alert-bg-levels, ('solid-icon': 11.5,'faded': 11.5));
}
$alert-border-level:                0;
$alert-bg-level:                    map-get($duke-alert-bg-levels, $duke-alert-style);
$alert-color-level:                 map-get($duke-alert-color-levels, $duke-alert-style);

$close-color:                       $body-color;
$close-text-shadow:                 0 1px 0 $body-bg;
$input-bg-level: ('light': 50%, 'dark': 25%);
@function _duke-input-bg() {
  @if $duke-color-scheme == 'light' {
    @return lighten($card-bg, 10%);
  } @else {
    @return darken($card-bg, 10%);
  }
}
$input-bg:                          _duke-input-bg();
$input-border-color:                $card-border-color;
//$input-disabled-bg:                 mix(#CCC, $input-bg, 25%);
$input-disabled-bg:                 $body-bg;
$input-color:                       duke_contrasted-color(mix($input-bg, $body-color, 25%), $input-bg) !default;
//$input-color:                       mix($input-bg, $body-color, 25%);
$input-placeholder-color:           mix($input-bg, $body-color, 50%);
$input-group-addon-color:           $body-color;
$input-group-addon-bg:              mix($body-color, $card-bg, 5%);
$custom-control-label-disabled-color: $text-muted;

$form-feedback-icon-valid-color:    duke_contrasted-color($success, $input-bg);
$form-feedback-icon-invalid-color:  duke_contrasted-color($danger, $input-bg);

$list-group-bg:                     rgba($body-color, 0.025);
$list-group-action-color:           $body-color;
$list-group-hover-bg:               rgba($body-color, 0.05);
$list-group-border-color:           rgba($body-color, .125) !default;
$list-group-disabled-color:         rgba($body-color, .5);
$list-group-action-active-bg:       $list-group-hover-bg;

$dropdown-border-color:             mix($body-color, $body-bg, 25%);
$dropdown-color:                    $body-color;
$dropdown-bg:                       mix(#FFFFFF, $body-bg, 10%);
$dropdown-divider-bg:               mix($body-color, $body-bg, 25%);

$dropdown-link-color:               $body-color;
$dropdown-link-hover-color:         darken($body-color, 5%);
$dropdown-link-hover-bg:            rgba($body-color, 0.1);

$dropdown-link-disabled-color:      mix($body-color, $body-bg, 50%);
$nav-link-disabled-color:           mix($body-color, $body-bg, 50%);

$dropdown-header-color:             $body-color;


$link-color:                        duke_contrasted-color($primary, $card-bg) !default;
$link-hover-color:                  darken($link-color, 15%) !default;

$btn-link-disabled-color:           $link-color;
$navbar-light-color:                rgba(#000, .6) !default;

$duke-icon-highlight-color:         if($primary == $card-bg, $body-color, $primary) !default;
$duke-profile-pic-bg-image: none !default;
