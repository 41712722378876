
%hovering-alert
{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
  z-index: $zindex-modal + 1;
  // @include transition($transition-base);
  @include box-shadow($box-shadow);
  &.show {
    top: 1rem;
    transform: translateY(0%) translateX(-50%);
  }

  @include media-breakpoint-down(xs) {
    width: calc(100% - 30px);
    text-align: center;
  }
}


@each $color, $value in $theme-colors {
  $bg: duke_theme-color-level-mix($color, $alert-bg-level);
  $fg: duke_contrasted-color(
    theme-color-level($color, $alert-color-level),
    $bg,
    $duke-alert-style == "solid" or
      ($duke-color-scheme == "dark" and
        ($duke-alert-style == "solid-icon" or $duke-alert-style == "faded"))
  );
  .alert-#{$color} {
    @include alert-variant(
        $bg,
        theme-color-level($color, $alert-border-level),
        $fg
    );
    &.alert-dismissible .close
    {
      color: darken($fg, 10%);
    }
    & > .icon {
      @if $duke-alert-style == 'solid-icon' {
        display: flex!important;
        background-color: theme-color-level($color, 0);
        color: theme-color-level($color, -12.5);
      } @else if $duke-alert-style == 'faded' {
        color: theme-color-level($color, 0);
      }
    }
  }
}


.alert-danger:not(.static),
.alert-success:not(.static),
.alert.floating
{
  @extend %hovering-alert;

  // inside a primary card during page transition
  router-outlet ~ *.ng-animating:not(footer):not(header) &
  {
    display: none !important;
  }
}

.alert-info:not(.floating),
.alert-warning:not(.floating),
.alert.static {
  .icon + div {
    flex-basis: 100%;
  }
}


.alert-dismissible {
  padding-right: $alert-padding-x;

  // Adjust close link position
  .close {
    padding: 0 (($alert-padding-x - ($close-font-size / 2)) / 2) ;
  }
}

.alert > .icon
{
  &:before {
    margin: auto;
  }
  margin-left: -$alert-padding-x;
  margin-right: -$alert-padding-x;
  margin-top: -$alert-padding-y;
  margin-bottom: 0;
  padding-top: $alert-padding-y;
  padding-bottom: $alert-padding-y;
  width: calc(100% + (#{$alert-padding-x} * 2));
  @include media-breakpoint-up(sm) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $alert-padding-x;
    padding-right: $alert-padding-x;
    @if $duke-alert-style == 'solid-icon' {
      margin-right: $alert-padding-x;
    } @else {
      margin-right: 0;
    }
    margin-bottom: -$alert-padding-y;
    width: calc(2.5rem + 1.875em);
  }
}


